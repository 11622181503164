import React from 'react';
import { Provider } from 'react-redux';
import NavigationBar from './Router/NavigationBar';
import store from './redux.js/store';
import { SnackbarProvider } from 'notistack';
import { NotificationProvider } from './context/NotificationProvider';
import { defaultTheme } from './constants/themeColors1';

function App() {
  // se verifica daca exista storedTheme
  const storedTheme = localStorage.getItem('theme');
  if (storedTheme === null) {
    // daca nu exista storedTheme atunci aceasta se trece pe tema default
    localStorage.setItem('theme', JSON.stringify(defaultTheme));
  }

  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <NotificationProvider>
          <NavigationBar />
        </NotificationProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
