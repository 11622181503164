import React, { useState, useEffect, useRef } from 'react';
import { View, Animated } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { fadeInAnimation, fadeOutAnimation } from '../constants/animations';
import { themeStyles } from '../constants/themeColors1';
import { ButonBaraLaterata } from '../components/caseteText';
import { FormularEdidareCont, FormularEwelink, FormularTuya } from '../components/formulare';
import DialogAlerta from '../components/DialogAlerta';
import { FormularDispozitive } from '../components/formularAdministrareDispozitive';
import { AccountCircleRounded, CloudRounded, ContrastRounded, LogoutRounded } from '@mui/icons-material';
import { FormularEditareTemeCuloare } from '../components/formularEditareTemaCuloare';

// Componenta Home
const SetariUtilizatorPage = () => {
  const { height, width } = useWindowDimensions();
  const fadeAnimFormularStanga = useRef(new Animated.Value(0)).current;
  const fadeAnimFormularEditareCont = useRef(new Animated.Value(0)).current;
  const fadeAnimFormularTuya = useRef(new Animated.Value(0)).current;
  const fadeAnimFormularEwelink = useRef(new Animated.Value(0)).current;
  const fadeAnimFormularEditareTema = useRef(new Animated.Value(0)).current;

  // La deschiderea paginii se activează animația de fade in
  useEffect(() => {
    Animated.parallel([fadeInAnimation(fadeAnimFormularStanga, 500, 400), fadeInAnimation(fadeAnimFormularEditareCont, 500, 600)]).start();
  }, []);

  const dispatch = useDispatch();
  const dateCont = useSelector((state) => state.dateCont.dateContUtilizator);
  const auth = useSelector((state) => state.auth.user);

  const [formularEditareContVizibil, setFormularEditareContVizibil] = useState(true);
  const [formularTuyaVizibil, setFormularTuyaVizibil] = useState(false);
  const [formularEwelinkVizibil, setFormularEwelinkVizibil] = useState(false);
  const [formularEditareTemeCuloareVizibil, setFormularEditareTemeCuloareVizibil] = useState(false);
  const [modalVizibil, setModalVizibil] = useState(false);

  // se ocupa cu aparitia formulatului de editare cont si disparitia celoralate formulare
  const handleFormularEditareCont = () => {
    Animated.parallel([
      fadeOutAnimation(fadeAnimFormularTuya),
      fadeOutAnimation(fadeAnimFormularEwelink),
      fadeOutAnimation(fadeAnimFormularEditareTema),
    ]).start(() => {
      setFormularTuyaVizibil(false);
      setFormularEwelinkVizibil(false);
      setFormularEditareTemeCuloareVizibil(false);
      setFormularEditareContVizibil(true);
      fadeInAnimation(fadeAnimFormularEditareCont, 500, 300).start();
    });
  };

  // se ocupa cu aparitia formularului Tuya si disparitia celorlate formulare
  const handleFormularTuya = () => {
    Animated.parallel([
      fadeOutAnimation(fadeAnimFormularEditareCont),
      fadeOutAnimation(fadeAnimFormularEwelink),
      fadeOutAnimation(fadeAnimFormularEditareTema),
    ]).start(() => {
      setFormularEwelinkVizibil(false);
      setFormularEditareContVizibil(false);
      setFormularEditareTemeCuloareVizibil(false);
      setFormularTuyaVizibil(true);
      fadeInAnimation(fadeAnimFormularTuya, 500, 300).start();
    });
  };

  // se ocupa cu aparitia formularului eWeLink si disparitia celorlate formulare
  const handleFormualarEwelink = () => {
    Animated.parallel([
      fadeOutAnimation(fadeAnimFormularEditareCont),
      fadeOutAnimation(fadeAnimFormularTuya),
      fadeOutAnimation(fadeAnimFormularEditareTema),
    ]).start(() => {
      setFormularTuyaVizibil(false);
      setFormularEditareContVizibil(false);
      setFormularEditareTemeCuloareVizibil(false);
      setFormularEwelinkVizibil(true);
      fadeInAnimation(fadeAnimFormularEwelink, 500, 300).start();
    });
  };

  // se ocupa de aparitia formularului pentru editarea temei de culoare
  const handleFormularTemeCuloare = () => {
    Animated.parallel([
      fadeOutAnimation(fadeAnimFormularEditareCont),
      fadeOutAnimation(fadeAnimFormularEwelink),
      fadeOutAnimation(fadeAnimFormularTuya),
    ]).start(() => {
      setFormularTuyaVizibil(false);
      setFormularEditareContVizibil(false);
      setFormularEwelinkVizibil(false);
      setFormularEditareTemeCuloareVizibil(true);
      fadeInAnimation(fadeAnimFormularEditareTema, 500, 300).start();
    });
  };

  const handleDeconectare = () => {
    dispatch({ type: 'LOGOUT_REQUEST', payload: { token: null } });
  };

  return (
    auth &&
    dateCont && (
      <View style={[themeStyles.fundalPaginaSetari, { height: height - height / 15 }]}>
        <View style={{ width: width, height: '100%', flexDirection: 'row' }}>
          {/* BARA LATERALA CU BUTOANE */}
          <View style={{ height: '100%', width: '75px', justifyContent: 'center' }}>
            <Animated.View style={[themeStyles.baraLaterala, { opacity: fadeAnimFormularStanga }]}>
              {/* BUTOANELE DE PE BARA LATERALA */}
              <View style={{ width: '100%', height: '100%', alignItems: 'center' }}>
                <ButonBaraLaterata
                  label={'Setări cont'}
                  icon={<AccountCircleRounded sx={themeStyles.iconButonNavBar} />}
                  onPress={() => handleFormularEditareCont()}
                />
                <ButonBaraLaterata
                  label={dateCont.servicii_cloud[1].nume}
                  icon={<CloudRounded sx={themeStyles.iconButonNavBar} />}
                  onPress={() => handleFormularTuya()}
                />
                <ButonBaraLaterata
                  label={dateCont.servicii_cloud[0].nume}
                  icon={<CloudRounded sx={themeStyles.iconButonNavBar} />}
                  onPress={() => handleFormualarEwelink()}
                />
                <ButonBaraLaterata
                  label={'Teme culoare'}
                  icon={<ContrastRounded sx={themeStyles.iconButonNavBar} />}
                  onPress={() => handleFormularTemeCuloare()}
                />
                <ButonBaraLaterata
                  label={'Deconectare'}
                  icon={<LogoutRounded sx={themeStyles.iconButonNavBar} />}
                  fieldStyle={{ position: 'absolute', bottom: 20 }}
                  onPress={() => setModalVizibil(true)}
                />
              </View>
            </Animated.View>
          </View>
          <View style={{ height: '95%', width: width - 100, justifyContent: 'center', alignSelf: 'center', marginLeft: 15 }}>
            {formularEditareContVizibil && <FormularEdidareCont fadeAnimation={fadeAnimFormularEditareCont} />}
            {/* FORMULARELE URMATOARE APAR DOAR DACA INDICELE CORESPUNZATOR DIN BAZA DE DATE ARE VALOAREA 0 */}
            {formularTuyaVizibil && dateCont.are_credentiale_tuya === 0 && <FormularTuya fadeAnimation={fadeAnimFormularTuya} />}
            {formularEwelinkVizibil && dateCont.are_credentiale_ewelink === 0 && <FormularEwelink fadeAnimation={fadeAnimFormularEwelink} />}

            {/* FORMULARELE URMATOARE APAR DOAR DACA INDICELE CORESPUNZATOR DIN BAZA DE DATE ARE VALOAREA 1 */}
            {formularTuyaVizibil && dateCont.are_credentiale_tuya === 1 && (
              <FormularDispozitive
                fadeAnimation={fadeAnimFormularTuya}
                dateCont={dateCont}
                auth={auth}
                numeServiciuCloud={dateCont.servicii_cloud[1].nume}
                idServiciuCloud={dateCont.servicii_cloud[1].id}
              />
            )}
            {formularEwelinkVizibil && dateCont.are_credentiale_ewelink === 1 && (
              <FormularDispozitive
                fadeAnimation={fadeAnimFormularEwelink}
                dateCont={dateCont}
                auth={auth}
                numeServiciuCloud={dateCont.servicii_cloud[0].nume}
                idServiciuCloud={dateCont.servicii_cloud[0].id}
              />
            )}

            {formularEditareTemeCuloareVizibil && <FormularEditareTemeCuloare fadeAnimation={fadeAnimFormularEditareTema} auth={auth} />}
          </View>
        </View>

        <DialogAlerta
          vizibil={modalVizibil}
          setVizibil={setModalVizibil}
          titlu={'Deconectare'}
          text={'Sunteți sigur că doriți să vă deconectați?'}
          buton={handleDeconectare}
        />
      </View>
    )
  );
};

export default SetariUtilizatorPage;
